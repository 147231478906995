<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <img :src="importedImg6" :alt="altText" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7 max-w-1400">
        <img :src="importedImg7" :alt="altText" class="imgItem7" />
      </div>
    </div>
    <div class="imgBox8 min-w-1400">
      <div class="img8 max-w-1400">
        <img :src="importedImg8" :alt="altText" class="imgItem8" />
      </div>
    </div>

    <div class="imgBox10 min-w-1400">
      <div class="img10 max-w-1400">
        <div class="box1">
          <h2>DOANH NGHIỆP TƯ NHÂN DỊCH VỤ CẦM ĐỒ KIM NGÂN PHÁT</h2>
          <div class="box2">
            <span
              >630 An Dương Vương, Phường 11, Quận 6, Thành <br />phố Hồ Chí Minh, Việt Nam</span
            >
          </div>
        </div>
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>Liên hệ chúng tôi</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/phone.png"
                  alt=""
                />
                <span>+84 578234082</span>
              </div>
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/email.png"
                  alt=""
                />
                <span>service@truongtaionline.com</span>
              </div>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>Về chúng tôi</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <li>
                  <a @click="scroll1">Câu chuyện của chúng ta</a>
                </li>
                <li>
                  <a @click="scroll2">Sứ mệnh</a>
                </li>
                <li>
                  <a @click="scroll3">Nhiệm vụ</a>
                </li>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2 >
              <router-link to="/about" tag="li" class="triangle">
                <a>Về sản phẩm </a>
              </router-link>
            </h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul3">
                <router-link to="/user" tag="li" class="triangle">
                  <a>Phát triển nhân viên</a>
                </router-link>
                <router-link to="/questions" tag="li" class="triangle">
                  <a>Trung tâm trợ giúp</a>
                </router-link>
                <li class="triangle">
                  <a
                    href="https://vgtiw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(AppStore)
                  </a>
                </li>
                <li class="triangle">
                  <a
                    href="https://vgtaw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(GooglePlay)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/truongtaionline/components/img/1_1.png";
import importedImg2 from "@/page/truongtaionline/components/img/1_2.png";
import importedImg3 from "@/page/truongtaionline/components/img/1_3.png";
import importedImg4 from "@/page/truongtaionline/components/img/1_4.png";
import importedImg5 from "@/page/truongtaionline/components/img/1_5.png";
import importedImg6 from "@/page/truongtaionline/components/img/1_6.png";
import importedImg7 from "@/page/truongtaionline/components/img/1_7.png";
import importedImg8 from "@/page/truongtaionline/components/img/1_8.png";
import importedImg9 from "@/page/truongtaionline/components/img/footer.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      altText: "图片",
    };
  },
  mounted() {
    this.scrollToImage1();
  },
  created() {
    this.gotoTop();
    // this.scrollToImage2();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    scrollToImage1() {
      const targetImage = this.$route.params.image;
      if (this.$route.params.image == "") {
        return;
      }
      if (targetImage === "1") {
        const distanceToScroll = 600;
        window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
      } else if (targetImage === "2") {
        const distanceToScroll = 2400;
        window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
      } else if (targetImage === "3") {
        const distanceToScroll = 3200;
        window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
      } else if (targetImage === "5") {
        const distanceToScroll = 2400;
        window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
      } else if (targetImage === "6") {
        const distanceToScroll = 3200;
        window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
      }
    },
    // scrollToImage2() {
    //   console.log("触发了第二个");
    //   const targetImage = this.$route.params.image2;
    //   if (this.$route.params.image2 == "") {
    //     return;
    //   }
    //   console.log("这是哪个", this.$route.params.image2);
    //   if (targetImage === "1") {
    //     const distanceToScroll = 600; // 设置要滚动的距离，单位为像素
    //     window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
    //   } else if (targetImage === "2") {
    //     console.log("第二个");
    //     const distanceToScroll = 2300; // 设置要滚动的距离，单位为像素
    //     window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
    //   } else if (targetImage === "3") {
    //     const distanceToScroll = 3000; // 设置要滚动的距离，单位为像素
    //     window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
    //   }
    // },
    scroll1() {
      const secondImage = document.querySelector(".imgBox2");
      secondImage.scrollIntoView({ behavior: "smooth" });
    },
    scroll2() {
      const sixthImage = document.querySelector(".imgBox6");
      const seventhImage = document.querySelector(".imgBox7");

      const position = (sixthImage.offsetTop + seventhImage.offsetTop) / 2.2;

      window.scrollTo({ top: position, behavior: "smooth" });
    },
    scroll3() {
      const secondImage = document.querySelector(".imgBox8");
      secondImage.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1 {
  /* margin-top: 80px; */
}
.imgBox1,
.imgBox4,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2,
.imgBox3,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,
.img4,
.img6,
.img7,
.img8,
.img9,
.img10 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2,
.img3,
.img5 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,
.imgItem4,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2 {
  max-width: 100%;
  margin-top: 30px;
}

.imgItem3 {
  max-width: 100%;
  margin-top: 80px;
  margin-bottom: 100px;
}
.imgItem5 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgBox10 {
  display: flex;
  justify-content: center;
  background-color: #242dda;
  height: 500px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.titleContainer1 {
  width: 400px;
  position: absolute;
  margin-top: 30px;
  margin-left: 200px;
}
.titleContainer2 {
  margin-top: 30px;
  width: 400px;
  margin-left: 600px;
}
.titleContainer3 {
  margin-top: 20px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 1000px;
}
.ul2 li {
  width: 220px;
}

li a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}
.a2 {
  width: 400px;
}
li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
.box1 {
  margin-left: 200px;
  margin-top: 50px;
}
.box2 {
  margin-top: 20px;
}
span {
  font-size: 20px;
  color: #ffffff;
}
.iconTextWrapper {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically centered */
  margin-top: 10px; /* Adjust margin as needed */
}

.iconTextWrapper img {
  margin-right: 10px; /* Adjust spacing between icon and text */
}
h2 a{
  font-size: 24px
}
.box3{
  margin-top: -20px;
}
h2{
  font-size: 24px ;
}
</style>
