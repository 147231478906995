<template>
  <div class="imgBox min-w-1400">
    <!-- <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div> -->
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div>
    <div class="imgBox7 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div>

    <div class="imgBox8 min-w-1400" @click="toggleNewImage(8)">
      <div class="img8 max-w-1400">
        <img
          :src="showNewImage8 ? AnswerImg8 : importedImg8"
          :alt="altText"
          class="imgItem8"
        />
      </div>
    </div>
    <div class="imgBox9 min-w-1400" @click="toggleNewImage(9)">
      <div class="img9 max-w-1400">
        <img
          :src="showNewImage9 ? AnswerImg9 : importedImg9"
          :alt="altText"
          class="imgItem9"
        />
      </div>
    </div>
    <div class="imgBox10 min-w-1400" @click="toggleNewImage(10)">
      <div class="img10 max-w-1400">
        <img
          :src="showNewImage10 ? AnswerImg10 : importedImg10"
          :alt="altText"
          class="imgItem10"
        />
      </div>
    </div>
    <div class="imgBox11 min-w-1400" @click="toggleNewImage(11)">
      <div class="img11 max-w-1400">
        <img
          :src="showNewImage11 ? AnswerImg11 : importedImg11"
          :alt="altText"
          class="imgItem11"
        />
      </div>
    </div>
    <div class="imgBox12 min-w-1400">
      <div class="img12 max-w-1400">
        <div class="box1">
          <h2>DOANH NGHIỆP TƯ NHÂN DỊCH VỤ CẦM ĐỒ KIM NGÂN PHÁT</h2>
          <div class="box2">
            <span
              >630 An Dương Vương, Phường 11, Quận 6, Thành <br />phố Hồ Chí Minh, Việt Nam</span
            >
          </div>
        </div>
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>Liên hệ chúng tôi</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/phone.png"
                  alt=""
                />
                <span>+84 578234082</span>
              </div>
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/email.png"
                  alt=""
                />
                <span>service@truongtaionline.com</span>
              </div>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>Về chúng tôi</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <li>
                  <a @click="jumpToTarget('1')">Câu chuyện của chúng ta</a>
                </li>
                <li>
                  <a @click="jumpToTarget('2')">Sứ mệnh</a>
                </li>
                <li>
                  <a @click="jumpToTarget('3')">Nhiệm vụ</a>
                </li>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2>
              <router-link to="/about" tag="li" class="triangle">
                <a>Về sản phẩm </a>
              </router-link>
            </h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul3">
                <router-link to="/user" tag="li" class="triangle">
                  <a>Phát triển nhân viên</a>
                </router-link>
                <router-link to="/questions" tag="li" class="triangle">
                  <a>Trung tâm trợ giúp</a>
                </router-link>
                <li class="triangle">
                  <a
                    href="https://vgtiw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(AppStore)
                  </a>
                </li>
                <li class="triangle">
                  <a
                    href="https://vgtaw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(GooglePlay)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";

import importedImg2 from "@/page/truongtaionline/components/img/3_1.png";
import importedImg3 from "@/page/truongtaionline/components/img/3_3.png";
import importedImg4 from "@/page/truongtaionline/components/img/3_4.png";
import importedImg5 from "@/page/truongtaionline/components/img/3_5.png";
import importedImg6 from "@/page/truongtaionline/components/img/3_6.png";
import importedImg7 from "@/page/truongtaionline/components/img/3_7.png";
import importedImg8 from "@/page/truongtaionline/components/img/3_8.png";
import importedImg9 from "@/page/truongtaionline/components/img/3_9.png";
import importedImg10 from "@/page/truongtaionline/components/img/3_10.png";
import importedImg11 from "@/page/truongtaionline/components/img/3_11.png";
import importedImg12 from "@/page/truongtaionline/components/img/footer.png";

import AnswerImg3 from "@/page/truongtaionline/components/img/3_3_3.png";
import AnswerImg4 from "@/page/truongtaionline/components/img/3_4_4.png";
import AnswerImg5 from "@/page/truongtaionline/components/img/3_5_5.png";
import AnswerImg6 from "@/page/truongtaionline/components/img/3_6_6.png";
import AnswerImg7 from "@/page/truongtaionline/components/img/3_7_7.png";
import AnswerImg8 from "@/page/truongtaionline/components/img/3_8_8.png";
import AnswerImg9 from "@/page/truongtaionline/components/img/3_9_9.png";
import AnswerImg10 from "@/page/truongtaionline/components/img/3_10_10.png";
import AnswerImg11 from "@/page/truongtaionline/components/img/3_11_11.png";

export default {
  data() {
    return {
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,

      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,
      AnswerImg11: AnswerImg11,

      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    jumpToTarget(image) {
      this.$router.push({ name: "/", params: { image: image } });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox2,
.imgBox12 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}
.imgBox2 {
  /* margin-top: 80px; */
}
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11 {
  display: flex;
  justify-content: center;
  width: 100%;
}

img {
  display: block;
}
.img2,
.img12 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}

.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11 {
  margin-left: 45px;
  margin-right: 45px;
  padding: 0 150px;
}

.imgItem2,
.imgItem12 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11 {
  max-width: 100%;
  margin-top: 20px;
}
.imgItem3 {
  margin-top: 80px;
}
.imgItem11 {
  margin-bottom: 100px;
}
.imgItem12 {
  margin-top: 120px;
}
.imgBox12 {
  display: flex;
  justify-content: center;
  background-color: #242dda;
  height: 500px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.titleContainer1 {
  width: 400px;
  position: absolute;
  margin-top: 30px;
  margin-left: 200px;
}
.titleContainer2 {
  margin-top: 30px;
  width: 400px;
  margin-left: 600px;
}
.titleContainer3 {
  margin-top: 20px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 1000px;
}
.ul2 li {
  width: 220px;
}

li a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}
.a2 {
  width: 400px;
}
li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
.box1 {
  margin-left: 200px;
  margin-top: 50px;
}
.box2 {
  margin-top: 20px;
}
span {
  font-size: 20px;
  color: #ffffff;
}
.iconTextWrapper {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically centered */
  margin-top: 10px; /* Adjust margin as needed */
}

.iconTextWrapper img {
  margin-right: 10px; /* Adjust spacing between icon and text */
}
h2 a {
  font-size: 24px;
}
h2{
  font-size: 24px ;
}
</style>